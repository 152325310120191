<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="showAdd"
    width="500"
    persistent
    @keydown.esc="onClose"
    @keydown.enter="saveUserImage"
  >
    <template v-slot:activator="{ on }">
      <v-btn @click="onNewClicked" class="button white" elevation="0" icon small v-on="on">
         <prosk-icon icon="camera" />
      </v-btn>
    </template>
    <details-card scrollable>
      <v-card-title>
        <prosk-title>
          {{ $t("pickUserImage") }}
        </prosk-title>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-file-input
              class="image-input custom-file-field"
              accept="image/*"
              @change="handleFileUpload"
              :placeholder="$t('noSelectedFiles')"
              prepend-icon=""
              v-model="profile_img"
              :error-count="errors.length"
              :error-messages="errors"
              ref="profileInput"
            >
              <template v-slot:prepend-inner>
                <button class="file-button main_text_color--text" @click="fileInputClick('profileInput')">
                  <v-icon size="16px">mdi-camera</v-icon>
                  <span>{{ $t('clickToAddImage') }}</span>
                </button>
              </template>
            </v-file-input>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <general-button :action="onClose" outlined :message="$t('cancel')"/>
        <general-button :action="saveUserImage" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import verifyImageSize from '@/components/socialvue/mixins/verifyImageSize';
import ProskIcon from '@/icons/ProskIcon.vue';
import GeneralButton from '../buttons/GeneralButton.vue';
import DetailsCard from '../cards/DetailsCard.vue';
import ProskTitle from '../markup/ProskTitle.vue';

export default {
  name: 'UserProfileImageDialog',
  components: { ProskIcon, GeneralButton, DetailsCard, ProskTitle },
  props: ['showAdd', 'onClose', 'newItem', 'isAdminEditing', 'userId'],
  mixins: [errorDialog, verifyImageSize],
  data () {
    return {
      profile_img: null,
      loading: false,
      errors: []
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    onNewClicked () {
      this.resetErrors();
      this.resetUserImage();
      this.newItem();
    },
    resetErrors () {
      this.errors = [];
    },
    getFormData () {
      const formData = new FormData();
      if (this.profile_img != null) {
        formData.append('profile_img', this.profile_img);
      }
      return formData;
    },
    resetUserImage () {
      this.profile_img = null;
    },
    saveUserImage () {
      this.resetErrors();
      this.loading = true;
      const formData = this.getFormData();
      const userId = this.isAdminEditing ? this.userId : this.currentUser.id;
      const url =
        process.env.VUE_APP_BASE_URL +
        `/api/users/${userId}/medias`;
      if (this.profile_img == null) {
        this.errors.push(this.$t('photoFieldRequired'));
        this.loading = false;
      } else {
        axios
          .post(url, formData)
          .then(() => {
            this.updateUser(userId);
            if (this.isAdminEditing) {
              this.updateUser(this.currentUser.id);
            }
            this.updatePercentage(userId);
            this.resetUserImage();
            this.loading = false;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
    }
  }
};
</script>
<style scoped>
.button {
  height: 40px;
  margin: 20px;
  padding: 9px;
  width: 40px;
}
</style>
